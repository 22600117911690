import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileNav = ({ navOpen, closeMobileNav, ...props }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`w-full h-full bg-[#243444] text-black mt-12 pt-8 fixed top-0 z-40 font-Poppins transform transition ease-in duration-500   ${
        navOpen ? "translate-y-0 animate-slideInNav" : "-translate-y-full "
      }`}
    >
      <Link to="/#home" onClick={closeMobileNav}>
        <div className="text-[#ffffff] hover:text-[#ffffff]">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Home
          </div>
        </div>
      </Link>
      <div
        className="text-[#ffffff] hover:text-[#ffffff]"
        onClick={() => setShow(!show)}
      >
        <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
          Strategies
        </div>
      </div>
      {show === true ? (
        <div>
          {/* <a href="/alpha-50" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                Alpha 50 - India
              </div>
            </div>
          </a> */}
          <a href="/alpha-100" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                ALpha 100 - India
              </div>
            </div>
          </a>
          <a href="/alpha-200" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                Alpha 200 - India
              </div>
            </div>
          </a>
          <a href="/alpha-500" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                Alpha 500 - India
              </div>
            </div>
          </a>
          <a href="/india-options" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                Options - India
              </div>
            </div>
          </a>

          <a href="/alpha-s&p" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                Alpha S&P - USA
              </div>
            </div>
          </a>
          <a href="/alpha-ndx" onClick={closeMobileNav}>
            <div className="text-[#ffffff] hover:text-[#ffffff]">
              <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
                ALpha NDX - USA
              </div>
            </div>
          </a>
          <a href="/usa-options" onClick={closeMobileNav}>
            <div className="flex text-[#ffffff] hover:text-[#ffffff]">
              <div className="ml-6 px-2 py-4 border-b border-white/10 cursor-pointer">
                Options - USA
              </div>
              <p className="bg-[#FFC9071A] mt-1 bg-opacity-10 border-[.1px] drop-shadow-[4px] rounded-[5px]  border-[#FFC907] mx-2  text-[#FFC907]  px-[6px] py-[1px] h-fit w-fit font-poppins text-[9px]">
                      Coming soon...
                    </p>
            </div>
          </a>
        </div>
      ) : null}

      <Link to="/#why-us" onClick={closeMobileNav}>
        <div className="text-[#ffffff] hover:text-[#ffffff]">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Why us?
          </div>
        </div>
      </Link>

      <Link to="/#philosophy" onClick={closeMobileNav}>
        <div className="text-[#ffffff] hover:text-[#ffffff]">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Philosophy
          </div>
        </div>
      </Link>
      <Link to="/#our-team" onClick={closeMobileNav}>
        <div className="text-[#ffffff] hover:text-[#ffffff]">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Our Team
          </div>
        </div>
      </Link>
      <a href="/comparison" onClick={closeMobileNav}>
        <div className="text-[#ffffff] hover:text-[#ffffff]">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
          Comparison
          </div>
        </div>
      </a>
      {/*-------------------------Contact on Email-------------------- */}
      <a
            href="https://cal.com/secondstreet"
            target="_blank"
            rel="noreferrer noopener"
            className=""
          >
            <div className="ml-8 mt-6 w-[200px] px-4 py-2.5 text-[14px] font-medium text-white border border-[#ffffff] rounded-[8px] hover:bg-[#ffffff] hover:text-[#000000] hover:scale-105 transition-all duration-150 ease-linear cursor-pointer">
              Schedule Consultation
            </div>
          </a>
          <a
            href="https://wealthspectrum.secondstreet.fund/wealthspectrum/portal/sign-in"
            className=""
          >
            <button className="ml-8 mt-4 w-[200px] px-4 py-2.5 text-[14px] bg-[#ffc907] text-[#000000] font-medium text-base rounded-[8px] hover:scale-105 active:scale-95 transition-all duration-150 ease-linear">
              Investor Login
            </button>
          </a>
    </div>
  );
};

export default MobileNav;
