import React from "react";
import styles from "../Faq.module.css";
const ShowTable = ({ monthlyReturns, titleOne, titleTwo }) => {
  const data2023 =
    monthlyReturns &&
    monthlyReturns.filter((row) => {
      if (row && row[0]) {
        const dateParts = row[0].split("/");
        const year = parseInt(dateParts[2]);
        return year === 2023;
      }
      return false; // Return false if row or row[0] is undefined
    });

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  // Create a Map to store the data by month
  const dataMap = new Map();

  // Iterate over the array and populate the dataMap
  data2023.forEach((row) => {
    const dateParts = row[0].split("/");
    const month = dateParts[1];
    dataMap.set(month, row);
  });

  // Create an array to hold the result
  const resultArray = [];

  // Iterate over the months array and add data to resultArray
  months.forEach((month) => {
    const rowData = dataMap.get(month);
    if (rowData) {
      resultArray.push(rowData);
    } else {
      // If data for the month is missing, add an array with empty values
      resultArray.push(["", "", ""]);
    }
  });

  // sum of all aplha 2024
  let sumAlpha2023 = 0;
  data2023.forEach((row) => {
    sumAlpha2023 += parseFloat(row[1].replace("%", ""));
  });

  // sum of all nifty 2024
  let sumNifty2023 = 0;
  data2023.forEach((row) => {
    sumNifty2023 += parseFloat(row[2].replace("%", ""));
  });

  const data2024 =
    monthlyReturns &&
    monthlyReturns.filter((row) => {
      if (row && row[0]) {
        const dateParts = row[0].split("/");
        const year = parseInt(dateParts[2]);
        return year === 2024;
      }
      return false; // Return false if row or row[0] is undefined
    });

  const monthsAlpha = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  // Create a Map to store the data by month
  const dataMap2 = new Map();

  // Iterate over the array and populate the dataMap
  data2024.forEach((row) => {
    const dateParts = row[0].split("/");
    const month = dateParts[1];
    dataMap2.set(month, row);
  });

  // Create an array to hold the result
  const resultAlpha = [];

  // Iterate over the months array and add data to resultArray
  monthsAlpha.forEach((month) => {
    const rowData = dataMap2.get(month);
    if (rowData) {
      resultAlpha.push(rowData);
    } else {
      // If data for the month is missing, add an array with empty values
      resultAlpha.push(["", "", ""]);
    }
  });

  // sum of all aplha 2024
  let sumAlpha2024 = 0;
  data2024.forEach((row) => {
    sumAlpha2024 += parseFloat(row[1].replace("%", ""));
  });

  // sum of all nifty 2024
  let sumNifty2024 = 0;
  data2024.forEach((row) => {
    sumNifty2024 += parseFloat(row[2].replace("%", ""));
  });



  const data2025 =
    monthlyReturns &&
    monthlyReturns.filter((row) => {
      if (row && row[0]) {
        const dateParts = row[0].split("/");
        const year = parseInt(dateParts[2]);
        return year === 2025;
      }
      return false;
    });

  // Create a Map to store the data by month
  const dataMap2025 = new Map();

  // Iterate over the array and populate the dataMap
  data2025.forEach((row) => {
    const dateParts = row[0].split("/");
    const month = dateParts[1];
    dataMap2025.set(month, row);
  });

  // Create an array to hold the result
  const resultAlpha2025 = [];

  // Iterate over the months array and add data to resultAlpha2025
  months.forEach((month) => {
    const rowData = dataMap2025.get(month);
    if (rowData) {
      resultAlpha2025.push(rowData);
    } else {
      resultAlpha2025.push(["", "", ""]);
    }
  });

  // Sum of all alpha 2025
  let sumAlpha2025 = 0;
  data2025.forEach((row) => {
    sumAlpha2025 += parseFloat(row[1].replace("%", ""));
  });

  // Sum of all nifty 2025
  let sumNifty2025 = 0;
  data2025.forEach((row) => {
    sumNifty2025 += parseFloat(row[2].replace("%", ""));
  });

  return (
    <div>
      {monthlyReturns && monthlyReturns.length !== 0 ? (
        <div className="relative">
        <div className={` mt-8 ${styles.table_container}`}>
          <table className={`${styles.tableUser1}`}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
                <th>YTD</th>
              </tr>
            </thead>
            <tbody>
              {/* For 2025  */}
           
              <tr>
                <td rowSpan={2} className="font-semibold -rotate-90">
                  2025
                </td>
                <td className="font-semibold">{titleOne}</td>
                {resultAlpha2025 &&
                  resultAlpha2025.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : parseFloat(row[1].replace("%", "")) <
                            parseFloat(row[2].replace("%", ""))
                          ? styles.redColor
                          : "text-center"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cellIndex === 1 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td
                  className={
                    parseFloat(sumAlpha2025) > parseFloat(sumNifty2025)
                      ? styles.greenColor
                      : styles.redColor
                  }
                >
                  {sumAlpha2025.toFixed(2)}%
                </td>
              </tr>
              <tr>
                <td className="font-semibold">{titleTwo}</td>
                {resultAlpha2025 &&
                  resultAlpha2025.map((row, rowIndex) => (
                    <td key={rowIndex} className="text-center">
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cellIndex === 2 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">{sumNifty2025.toFixed(2)}%</td>
              </tr>


              {/* For 2024  */}
              <tr>
                <td rowSpan={2} className="font-semibold -rotate-90">
                  2024
                </td>
                <td className="font-semibold">{titleOne}</td>
                {resultAlpha &&
                  resultAlpha.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : parseFloat(row[1].replace("%", "")) <
                            parseFloat(row[2].replace("%", ""))
                          ? styles.redColor
                          : "text-center"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cellIndex === 1 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td
                  className={
                    parseFloat(sumAlpha2024) > parseFloat(sumNifty2024)
                      ? styles.greenColor
                      : styles.redColor
                  }
                >
                  {sumAlpha2024.toFixed(2)}%
                </td>
              </tr>
              <tr>
                <td className="font-semibold">{titleTwo}</td>
                {resultAlpha &&
                  resultAlpha.map((row, rowIndex) => (
                    <td key={rowIndex} className="text-center">
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cellIndex === 2 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">{sumNifty2024.toFixed(2)}%</td>
              </tr>

              {/* For 2023  */}

              <tr>
                <td rowSpan={2} className="font-semibold -rotate-90">
                  2023
                </td>
                <td className="font-semibold">{titleOne}</td>
                {resultArray &&
                  resultArray.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className={
                        row[1] &&
                        row[2] &&
                        parseFloat(row[1].replace("%", "")) >
                          parseFloat(row[2].replace("%", ""))
                          ? styles.greenColor
                          : parseFloat(row[1].replace("%", "")) <
                            parseFloat(row[2].replace("%", ""))
                          ? styles.redColor
                          : "text-center"
                      }
                    >
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 1 && cell}
                          {cellIndex === 1 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                 <td
                  className={
                    parseFloat(sumAlpha2023) > parseFloat(sumNifty2023)
                      ? styles.greenColor
                      : styles.redColor
                  }
                >
                  {sumAlpha2023.toFixed(2)}%
                </td>
              </tr>
              <tr>
                <td className="font-semibold">{titleTwo}</td>
                {resultArray &&
                  resultArray.map((row, rowIndex) => (
                    <td key={rowIndex} className="text-center">
                      {row.map((cell, cellIndex) => (
                        <span key={cellIndex}>
                          {cellIndex === 2 && cell}
                          {cellIndex === 2 && cell === "" && "-"}
                        </span>
                      ))}
                    </td>
                  ))}
                <td className="text-center">{sumNifty2023.toFixed(2)}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span className="font-semibold absolute -bottom-[25px] text-[13px] text-gray-800 ">
        *Note: Above numbers are pre-tax, pre management fees.
      </span>
      </div>
      ) : (
        <div className="py-4">COMING SOON</div>
      )}
       
    </div>
  );
};

export default ShowTable;
