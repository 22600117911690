import React from "react";
import { Route, Routes } from "react-router-dom";

import IndexPage from "./components/IndexPage/IndexPage";
import StrategyPage from "./components/StrategyPage/StrategyPage";
import AboutUs from "./components/AboutUs/AboutUs";
import Alpha50 from "./components/StrategyPage/Alpha50";
import Alpha200 from "./components/StrategyPage/Alpha200";
import Alpha500 from "./components/StrategyPage/Alpha500";
import AlphaSNP from "./components/StrategyPage/AlphaSNP";
import AlphaNDX from "./components/StrategyPage/AlphaNDX";
import IndiaOptions from "./components/StrategyPage/IndiaOptions";
import UsaOptions from "./components/StrategyPage/UsaOptions";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import Comparision from "./components/Comparison/Comparison";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/about-us" exact element={<AboutUs />} />
        {/* <Route path="/alpha-50" exact element={<Alpha50 />} /> */}
        <Route path="/alpha-100" exact element={<StrategyPage />} />
        <Route path="/alpha-200" exact element={<Alpha200 />} />
        <Route path="/alpha-500" exact element={<Alpha500 />} />
        <Route path="/alpha-s&p" exact element={<AlphaSNP />} />
        <Route path="/alpha-ndx" exact element={<AlphaNDX />} />
        <Route path="/india-options" exact element={<IndiaOptions />} />
        <Route path="/usa-options" exact element={<UsaOptions />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/comparison" exact element={<Comparision />} />
        <Route
          path="/terms-and-conditions"
          exact
          element={<TermsAndConditions />}
        />
        <Route path="/" element={<IndexPage />} />
      </Routes>
    </div>
  );
}

export default App;
