import React from "react";

const ConnectNow = () => {
  return (
    <a
      href="https://cal.com/secondstreet"
      target="_blank"
      rel="noreferrer noopener"
      className="px-16 md:px-6 py-3  bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[8px] transition-all duration-150 ease-linear hover:scale-105 cursor-pointer"
    >
      Connect Now
    </a>
  );
};
export default ConnectNow;
