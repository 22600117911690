import React from "react";
import styles from "./PrivacyPolicy.module.css";

function ContainerPoints(props) {
  return (
    <div
      className={props.no === "1" ? styles.sectionmain : styles.sectionmain1}
    >
      {props.no && props.heading && (
        <div className={styles.Heading}>
          {props.no && props.no}.{props.heading && props.heading}
        </div>
      )}
      <div style={{margin: "0px"}} className={styles.SubHeading}>
        <span>{props.paraHeading1 && props.paraHeading1}</span>
        <span>{props.paraHeading2 && props.paraHeading2}</span>
        <span>{props.paraHeading3 && props.paraHeading3}</span>
        <span>{props.paraHeading4 && props.paraHeading4}</span>
      </div>
      <div style={{ lineHeight: "28px", marginLeft: "40px" }} className={styles.SubHeadingPoints}>
        <ul style={{ listStyleType: "disc" }}>
          {props.bullet1 && <li>{props.bullet1 && props.bullet1}</li>}
          {props.bullet2 && <li>{props.bullet2 && props.bullet2}</li>}
          {props.bullet3 && <li>{props.bullet3 && props.bullet3}</li>}
          {props.bullet4 && <li>{props.bullet4 && props.bullet4}</li>}
          {props.bullet5 && <li>{props.bullet5 && props.bullet5}</li>}
          {props.bullet6 && <li>{props.bullet6 && props.bullet6}</li>}
          {props.bullet7 && <li>{props.bullet6 && props.bullet7}</li>}
          {props.bullet8 && <li>{props.bullet6 && props.bullet8}</li>}
          {props.bullet9 && <li>{props.bullet6 && props.bullet9}</li>}
          {props.bullet10 && <li>{props.bullet6 && props.bullet10}</li>}
          {props.bullet11 && <li>{props.bullet6 && props.bullet11}</li>}
          {props.bullet12 && <li>{props.bullet6 && props.bullet12}</li>}
          {props.bullet13 && <li>{props.bullet6 && props.bullet13}</li>}
        </ul>
      </div>
      <div className={styles.SubHeading}>
        <span>{props.paraFooter1 && props.paraFooter1}</span>
        <span>{props.paraFooter2 && props.paraFooter2}</span>
        {props.paraFooter10 && (
          <p>{props.paraFooter10 && props.paraFooter10}</p>
        )}
      </div>
    </div>
  );
}

export default ContainerPoints;
