import React, { useEffect, useRef, useState } from "react";
import animation from "../common/animation";
import StrategyNavbar from "../Navbar/StrategyNavbar";

import GetStarted from "./GetStarted";
// import Faq from "./Faq";
// import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";

import Pdf from "../assests/pdf.svg";

import FixedComponent from "./FixedComponent";
import FooterDisclaimer from "./FooterDiscliamer";
import FirstDisclaimer from "./FirstDisclaimer";
import LivePerformace from "./LivePerformance";
import StrategyDescription from "./StrategyDescription";
import BacktestedPerformance from "./BacktestedPerformace";
import WhyAlpha from "./WhyAlpha";
import OtherDiscription from "./OtherDescription";

import axios from "axios";
import ContactUsForm from "../Footer/ContactUsForm";
import Backtested from "../common/Backtested";
import OtherBacktested from "../common/OtherBackTested";
import Statistics from "../common/Statistics";
const data = [
  {
    heading: "Management Fee",
    desc: "1%",
  },
  {
    heading: "Performance Fee",
    desc: "20% Above Benchmark",
  },
  {
    heading: "Min. Investment",
    desc: "$100,000",
  },
  {
    heading: "Redemptions",
    desc: "Daily with 1 day notice",
  },
  {
    heading: "IRA Eligible",
    desc: "Yes",
  },
  {
    heading: "Investor Type",
    desc: "Accredited",
  },
  {
    heading: "Investor Eligibility",
    desc: "Global",
  },
];

const newData = [
  {
    imageUrl: Pdf,
    link: "https://drive.google.com/file/d/1utZ1_SQvnbtyvueSRp5IUipo1govikXX/view?usp=sharing",
    name: "Investment - US Markets. Presentation",
  },
  {
    imageUrl: Pdf,
    link: "https://drive.google.com/file/d/1jN8eCBCDaB5bHLX6eVFxa9LFGv1iOGmv/view",
    name: "Memorandum and Supplemental Memorandum",
  },
  {
    imageUrl: Pdf,
    link:"https://drive.google.com/file/d/1x5dhq2cmd3tDa5lC6Wfj5yGpbmF0og_y/view?usp=sharing",
    name: "Alpha NDX Factsheet",
  },
  {
    imageUrl: "",
    link: "https://dashboard.secondstreet.fund",
    name: "Historical Performance Dashboard",
  },
  {
    imageUrl: "",
    link: "https://tr.ee/hUy1O-54C2",
    name: "Contact - Satyam Bhasin",
  },
];

const backtested = [
  {
    imageUrl: Pdf,
    name: "Tear Sheet",
  },
  {
    imageUrl: Pdf,
    name: "Presentation",
  },
  {
    imageUrl: Pdf,
    name: "Investment Memo",
  },
];

const AlphaNDX = () => {
  const sidebarRef = useRef(null);
  const contentWrapperRef = useRef(null);

  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const contentWrapper = contentWrapperRef.current;

      if (sidebar && contentWrapper) {
        const scrollTop = window.scrollY;

        if (scrollTop >= 1800) {
          setIsAtEnd(true);
        } else {
          setIsAtEnd(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  const [liveData, setLiveData] = useState();
  const getData = () => {
    axios
      .get(
        "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/alpha-nasdaq"
      )
      .then((res) => {
        setLiveData(res.data.values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // chart data
  const alphaNasdaqPerformceAccountValue = liveData
    ? liveData.map((item) => [item[0], item[1], item[2]])
    : [];
  const chartData =
    alphaNasdaqPerformceAccountValue &&
    alphaNasdaqPerformceAccountValue
      .filter(
        (innerArray) =>
          innerArray &&
          innerArray.every((value) => value !== undefined && value !== "")
      )
      .map((innerArray, index) =>
        innerArray.map((value, columnIndex) => {
          // Skip the first column
          if (columnIndex === 0) {
            return value;
          }

          // Remove the leading dollar sign and commas, then convert to a number
          return columnIndex === 1 || columnIndex === 2
            ? parseFloat(value.replace(/^\$|,/g, ""))
            : value;
        })
      );

  // table Data
  const newNasdaqData = liveData
    ? liveData.map((item) => [item[0], item[5], item[6]])
    : [];
  const dataNasdaqArray = newNasdaqData ? newNasdaqData : [];

  const alphaNasdaqMonthlyReturns = [dataNasdaqArray[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const uniqueNasdaqMonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < dataNasdaqArray.length; i++) {
    const currentDate = dataNasdaqArray[i][0]; // assuming this is in "DD/MM/YYYY" format

    // Ensure that currentDate is a valid string
    if (currentDate && typeof currentDate === "string") {
      const dateParts = currentDate.split("/"); // Splitting "DD/MM/YYYY" into an array
      if (dateParts.length === 3) {
        const month = dateParts[1]; // Get month part
        const year = dateParts[2]; // Get year part
        const currentMonthKey = `${month}/${year}`; // Construct the month/year key

        // Check if the current month is not already in the uniqueMonthsSet and the data is not empty or undefined
        if (
          !uniqueNasdaqMonthsSet.has(currentMonthKey) &&
          dataNasdaqArray[i].every(
            (value) => value !== undefined && value !== ""
          )
        ) {
          alphaNasdaqMonthlyReturns.push(dataNasdaqArray[i]);
          uniqueNasdaqMonthsSet.add(currentMonthKey);
        }
      } else {
        console.warn("Unexpected date format:", currentDate);
      }
    }
  }

  const [show, setShow] = useState(false);
  return (
    <div>
      <StrategyNavbar />
      <div className=" relative  flex flex-col md:flex-row justify-between pt-24 md:pt-28  pb-8 md:pb-32 px-4 md:px-36">
        <div className="w-full md:w-[75%] md:min-h-full ">
          <div className=" text-xl md:text-[32px] md:leading-[62px] font-rubik font-bold">
            Alpha NDX Strategy
          </div>
          <div className="text-sm font-rubik text-[#000000]/70">
            Momentum Strategy to beat Nasdaq 100 with its own constituents.
          </div>
          <div className="pt-12 font-rubik text-[18px] opacity-70">
            {" "}
            Alpha NDX 100 employs quantitative algorithms to pick 10 stocks from
            the Nasdaq 100. Recognizing the higher beta of tech stocks, this
            strategy pursues greater returns over a three-year period but
            acknowledges the potential for higher drawdowns than a pure S&P 500
            based strategy. The strategy balances the pursuit of returns with
            managing drawdown risks.
          </div>
          <StrategyDescription />
          <FirstDisclaimer />
          <LivePerformace
            monthlyReturns={
              alphaNasdaqMonthlyReturns && alphaNasdaqMonthlyReturns
            }
            titleOne="Alpha NDX"
            titleTwo="Nifty NDX"
            performaceData={chartData && chartData}
            tabName="alpha-nasdaq"
          />
          <BacktestedPerformance backtested={backtested} />
          <OtherBacktested page="ALPHA-NDX" />
          <Statistics page="NDX 100" />
          {/* <WhyAlpha title="NDX" /> */}
          {/* <OtherDiscription /> */}
        </div>
        <div ref={sidebarRef} className="sidebar min-h-full w-full md:w-[40%]">
          <FixedComponent
            contentWrapperRef={contentWrapperRef}
            isAtEnd={isAtEnd}
            data={data}
            newData={newData}
          />
        </div>
      </div>
      <FooterDisclaimer />
      <GetStarted />
      {/*  <Faq />  <UpdateBox />*/}

      <Footer setShow={setShow} />
      {show === true ? <ContactUsForm setShow={setShow} show={show} /> : null}
    </div>
  );
};

export default AlphaNDX;
