import React from "react";
import styles from "./Terms.module.css";

import ContainerPoints from "../PrivacyPolicy/ContainerPoints";

function TermsSeven(props) {
  return (
    <div>
      {(props.no || props.heading) && (
        <div style={{textAlign: "left"}} className={styles.Heading}>
          {props.no}{props.no ? ". " : ""}
          {props.heading}
        </div>
      )}
      <ContainerPoints
        paraHeading1={props.paraHeading1}
        bullet1={props.bullet1}
        bullet2={props.bullet2}
        bullet3={props.bullet3}
        bullet4={props.bullet4}
        bullet5={props.bullet5}
        bullet6={props.bullet6}
        bullet7={props.bullet7}
      />
      {props.footer && (
        <div className={styles.SubHeading}>
          <p>
            You can contact our Copyright Agent via email at{" "}
            <a href={`mailto:abhimanyu@secondstreet.fund`}>
              abhimanyu@secondstreet.fund.
            </a>
          </p>
        </div>
      )}
      {!props.footer && (
        <ContainerPoints
          paraHeading1={props.paraHeading2}
          bullet1={props.bullet7}
          bullet2={props.bullet8}
          bullet3={props.bullet9}
          bullet4={props.bullet10}
          bullet5={props.bullet11}
          bullet6={props.bullet12}
          bullet7={props.bullet13}
          bullet8={props.bullet14}
          bullet9={props.bullet15}
        />
      )}
    </div>
  );
}

export default TermsSeven;
