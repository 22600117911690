import React, { useEffect } from "react";
import animation from "../../common/animation";

import Sebi from "../../assests/companies/SEBI_logo.svg";
import Fsc from "../../assests/companies/Financial_Services.svg";
import Emkay from "../../assests/companies/Emkay_1.svg";
import SBI from "../../assests/companies/SBI.svg";
import Nuvama from "../../assests/companies/Nuvama.svg";
import Cimb from "../../assests/companies/CIMB.svg";
import Ons from "../../assests/companies/NS_Finserv.svg";
import PhillipCapital from "../../assests/companies/PhillipCapital.svg";
import InteractiveBrokers from "../../assests/companies/Interactive_Brokers.svg";
import Dhanlogo from "../../assests/companies/Dhan.svg";
import SBMGroup from "../../assests/companies/SBM.svg";
import Choice from "../../assests/companies/Choice.svg";
import Dovetail from "../../assests/companies/dovetail_logo.png";

const data = [
  { imgUrl: Sebi, tag: "Sebi " },
  { imgUrl: Fsc, tag: "Fsc" },
];

const data2 = [
 
  { imgUrl: Ons, tag: "compliance" },
  { imgUrl: Emkay, tag: "Broking" },
  { imgUrl: SBI, tag: "Banking" },
  // { imgUrl: Cimb, tag: "Broking" },
  { imgUrl: Dovetail, tag: "Broking" },
  { imgUrl: PhillipCapital, tag: "PhillipCapital" },
  { imgUrl: SBMGroup, tag: "SBMGroup" },
  { imgUrl: Dhanlogo, tag: "Dhanlogo" },
  { imgUrl: Nuvama, tag: "custodian" },
  { imgUrl: InteractiveBrokers, tag: "InteractiveBrokers" },
  { imgUrl: Choice, tag: "Choice" },
  
];

const Companies = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  return (
    <div className="w-full bg-[#F5F5F5]">
      <div className="flex-row w-max-full  md:pt-[90px] md:pb-[90px] items-center justify-center">
        <div className="md:flex relative justify-center items-center  top-0 h-fit md:h-[25vh] pb-8 md:rounded-[30px] md:w-[83%] mx:4 md:mx-auto mt-10 md:drop-shadow-2xl md:my-2 md:px-[150px] pt-4 md:pt-0 bg-white md:pl-2">
          <div className="flex flex-row  justify-center md:w-[40%] md:mt-12 border-[#000000]/40 md:pr-8">
            {data.map((ele, i) => (
              <div key={i} className="hiddenAnimation">
                <img src={ele.imgUrl} className="w-[150px] md:w-[210px] relative bottom-2" alt={ele.tag} />
              </div>
            ))}
          </div>
           
          <div className="hiddenAnimation  border-dashed border-l-2 text-2xl md:text-4xl pl-6 md:py-6 md:mt-6 md:w-[60%] text-[#211f54] md:text-[36px] font-sans font-bold text-center">
            Fully Regulated and Licensed
          </div>
        </div>
        <div className="md:flex items-center justify-center md:rounded-[30px] h-[45vh] md:h-[25vh] md:w-[83%] md:my-8 mx-auto md:drop-shadow-2xl md:px-[20px] bg-white md:pl-2">
          <div className="hiddenAnimation md:w-[20%] border-dashed border-r-2 w-full md:py-8 pt-8  text-[40px] text-[#211f54] md:text-[38px] md:leading-[62px]  md:text-5xl font-sans font-bold text-center ">
            Partners
          </div>
          <div className="relative  md:top-0 md:pl-0 mx-0 md:w-[80%] pr-0 overflow-hidden">
            <div className="carousel-container px-0 mx-0 pt-4 md:pt-0">
              <div className="carousel-content mx-0 px-0 ">
                {data2.map((ele, i) => (
                  <div key={i} className="carousel-item px-0 mx-0">
                    <img src={ele.imgUrl} alt={ele.tag} />
                  </div>
                ))}
                {/* Duplicate the logos to create a seamless scroll effect */}
                {data2.map((ele, i) => (
                  <div key={i + data2.length} className="carousel-item">
                    <img src={ele.imgUrl} alt={ele.tag} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
